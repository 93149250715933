// useViteErrorHandling.ts
import { useEffect } from 'react';

const useViteErrorHandling = () => {
    useEffect(() => {
        const handlePreloadError = () => {

            window.location.reload(); // Refresh the page on preload error
        };

        window.addEventListener('vite:preloadError', handlePreloadError);

        return () => {
            window.removeEventListener('vite:preloadError', handlePreloadError);
        };
    }, []);
};

export default useViteErrorHandling;
