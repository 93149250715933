import { useContext } from 'react'
import { BrowserEventProviderContext } from './BrowserEventProvider'

const useBrowserEventProvider = () => {
    return useContext(BrowserEventProviderContext)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any


export default useBrowserEventProvider