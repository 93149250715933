import { FC } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, IIConType, KTIcon, WithChildren } from '../../../helpers'
import { TIConName } from '../../../helpers/icons-config/icons'
import { useSelector } from 'react-redux'
import { selectSidebarCounter } from '../../../../app/app.slice'

type Props = {
  to: string;
  title: string;
  iconType?: IIConType;
  icon?: TIConName;
  fontIcon?: string;
  hasBullet?: boolean;
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  iconType = 'duotone',
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const itemCount = useSelector(selectSidebarCounter)
  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', { active: isActive, 'fw-bolder': isActive })} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon '>
            <KTIcon iconName={icon} iconType={iconType} className='fs-2' />
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
        {itemCount?.[to] ? <span className="badge badge-sm badge-circle badge-light-primary">{itemCount?.[to]}</span> : null}
      </Link>
      {children}
    </div>
  )
}

export { AsideMenuItem }
