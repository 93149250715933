import { createContext, ReactNode, useEffect, useRef } from 'react'

interface IBrowserTabEvent {
    eventName: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (data?: any) => void;
}

interface IEvents extends Record<string, IBrowserTabEvent> { }

interface IBrowserEventProviderContext {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sendTabEvent: (eventName: string, payload?: any) => void;
    broadCastChannelRef?: React.MutableRefObject<BroadcastChannel>;
}
export const BrowserEventProviderContext = createContext<IBrowserEventProviderContext>({ broadCastChannelRef: undefined, sendTabEvent: () => { } })

export const BROADCAST_EVENT = {
    LOGOUT_EVENT: 'logout',
    LOGIN_EVENT: 'login',
};

const BrowserEventProvider = ({ children, events }: { children: ReactNode; events: IEvents }) => {
    const broadCastChannelRef = useRef<BroadcastChannel>(new BroadcastChannel('broadcast-event'))

    useEffect(() => {
        // Check if BroadcastChannel is supported

        if (typeof BroadcastChannel !== "undefined") {

            // Listen for messages from other tabs
            const broadcastChannelEventHandler = (event: MessageEvent) => {
                let eventData = {};
                const eventName = event.data.eventName
                if (event.data && Object.values(BROADCAST_EVENT).includes(eventName)) {
                    eventData = event.data
                }
                const e = events[eventName];
                if (e) e.callback(eventData);

            };
            const broadcastChannel = broadCastChannelRef.current
            // window.addEventListener('message', broadcastChannelEventHandler);
            broadcastChannel.onmessage = (event) => {
                broadcastChannelEventHandler(event);
            };
            broadcastChannel.onmessageerror = (e) => {
                console.error(e);

            };
            return () => {
                // Close only when unmounting
                broadcastChannel.close();
                // window.removeEventListener('message', broadcastChannelEventHandler);
            };
        } else {
            // Fallback to localStorage
            const localStorageEventHandler = (event: StorageEvent,) => {
                let eventData = {};
                const eventName = event.key as string;
                if (event.key && Object.values(BROADCAST_EVENT).includes(event.key)) {
                    eventData = event.newValue ? JSON.parse(event.newValue as string) : {};
                    const e = events[eventName];
                    if (e) {
                        e.callback(eventData);
                    }
                }
            };
            window.addEventListener('storage', localStorageEventHandler);
            return () => {
                window.removeEventListener('storage', localStorageEventHandler);
            };
        }
    }, [events]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sendTabEvent = (eventName: string, payload?: any) => {
        const eventData = {
            eventName,
            timestamp: Math.random(),
            ...payload,
        };
        const channel = broadCastChannelRef.current;

        // Check if BroadcastChannel exists
        if (channel) {
            try {
                channel.postMessage(eventData); // Send structured data to other tabs
            } catch (error) {
                console.error("Failed to post message:", error);
            }
        }

        // Handle event in the current tab immediately (Single tab scenario)
        if (!document.hidden) {
            const e = events[eventName];
            if (e) {
                e.callback(eventData); // Manually trigger the event callback in the current tab
            }
        } else {
            // Fallback to localStorage to simulate cross-tab events
            try {
                localStorage.setItem(eventName, JSON.stringify(eventData)); // Store structured data
            } catch (error) {
                console.error("Failed to set item in localStorage:", error);
            }
        }
    };


    return (
        <BrowserEventProviderContext.Provider
            value={{
                sendTabEvent,
                broadCastChannelRef,
            }}
        >
            {children}
        </BrowserEventProviderContext.Provider>
    )
}

export default BrowserEventProvider