export const BASE_URL = `${import.meta.env.VITE_APP_PROD_API_URL}`

export const BACKEND_BASE_URL = `${import.meta.env.VITE_APP_PROD_BACKEND_BASE_URL}`
export const USER_MANAGEMENT_BASE_URL = `${process.env.NODE_ENV === "development"
    ? import.meta.env.VITE_APP_API_URL :
    import.meta.env.VITE_APP_USER_MANAGEMENT_API_URL}`

export const BackendAuthApi: string = `${BASE_URL}/backend/BackendAuth`
export const MasterListApi: string = `${BASE_URL}/backend/MasterList/getMasterListBackend`
export const MasterListV2Api: string = `${BASE_URL}/backend/MasterList`;
export const PlacesCityMasterApi: string = `${BASE_URL}/backend/PlacesCityMaster`;
export const PlacesAreaMasterApi: string = `${BASE_URL}/backend/PlacesAreaMaster`;
export const PlacesSubAreaMasterApi: string = `${BASE_URL}/backend/PlacesSubAreaMaster`;
export const PlacesRouteMasterApi: string = `${BASE_URL}/backend/PlacesRoute`;
export const PlacesHubMasterApi: string = `${BASE_URL}/backend/PlacesHubsMaster`;
export const ProductBrandMasterApi: string = `${BASE_URL}/backend/ProductBrandMaster`;
export const ProductCategoryMasterApi: string = `${BASE_URL}/backend/ProductCategoryMaster`;
export const ProductMasterApi: string = `${BASE_URL}/backend/ProductMaster`;
export const ProductPackagingMasterApi: string = `${BASE_URL}/backend/ProductPackagingMaster`;
export const DeliveryUserRelatedApi: string = `${BASE_URL}/backend/users/delivery`;
export const CustomerProfileApi: string = `${BASE_URL}/backend/CustomerProfile`;
export const WalletApi: string = `${BASE_URL}/backend/walletBackend`;
export const CustomerPauseApi: string = `${BASE_URL}/backend/Pause`;
export const BannerMasterApi: string = `${BASE_URL}/backend/BannerMaster`;
export const DeliveryConfigApi: string = `${BASE_URL}/backend/DeliveryConfig`
export const SettingsWalletApi: string = `${BASE_URL}/backend/SettingsWallet`
export const CustomerCalendarApi: string = `${BASE_URL}/backend/CustomerCalendar`
export const DashBoardRelatedApi: string = `${BASE_URL}/backend/reports/DashBoardRelated`;
export const OrdersBackendApi: string = `${BASE_URL}/backend/OrdersBackend`;
export const CustomerRelationsApi: string = `${BASE_URL}/backend/CustomerRelations`;
export const BackendUtilitiesApi: string = `${BASE_URL}/backend/BackendUtilities`;
export const ReportBaseApi: string = `${BASE_URL}/backend/reports`;


export const MasterListV1Api: string = `${BASE_URL}/MasterList`;
export const CustomerRegistrationApi: string = `${BASE_URL}/CustomerRegistration`;
export const CustomerSubsriptionApi: string = `${BASE_URL}/backend/CustomerSubscription`;
export const CustomerOrderApi: string = `${BASE_URL}/CustomerOrder`;
export const BuyOnceCartApi: string = `${BASE_URL}/Cart`;
export const DeliveryBaseApi: string = `${BASE_URL}/delivery`
export const OrderDetailsApi: string = `${BASE_URL}/OrderDetails`
export const DeliveryCalendarApi: string = `${BASE_URL}/DeliveryCalendar`
export const DeliveryOperationsApi: string = `${DeliveryBaseApi}/DeliveryOperations`
export const LocationBasedReportsApi: string = `${ReportBaseApi}/LocationBasedReports`

export const WalletRelatedApi: string = `${ReportBaseApi}/WalletRelated`;
export const OrderRelatedApi: string = `${ReportBaseApi}/OrderRelated`;
export const SubscriptionRelatedApi: string = `${ReportBaseApi}/SubscriptionRelated`;
export const CustomerActivityRelatedApi: string = `${ReportBaseApi}/CustomerActivityRelated`;
export const InvoiceRelatedApi: string = `${ReportBaseApi}/InvoiceRelated`;
export const SalesRelatedApi: string = `${ReportBaseApi}/SalesRelated`;
export const CustomerRelationReportsApi: string = `${ReportBaseApi}/CustomerRelations`;
export const CustomerProfileReportsApi: string = `${ReportBaseApi}/CustomerProfile`;