import { IAppNotificationPayload } from "../../app.interfaces"

interface ISignalRNotificationEvent {
    [x: string]: IAppNotificationPayload
}
export const signalRNotificationEvent: ISignalRNotificationEvent = {
    'rechargeMessage': {
        type: 'success',
        title: 'Recharge Notification',
        notificationType: 'alert',
        msg: '',
    },
    'customerTicketMessage': {
        type: 'info',
        title: 'New Ticket',
        notificationType: 'toast',
        msg: 'New Ticket arrived',
    },
    'ticketCount': {
        type: 'info',
        title: 'Ticket Closed',
        notificationType: 'alert',
        msg: '1 Ticket Closed',
    },
    'logoutUser': {
        type: 'info',
        title: 'Permissions updated',
        notificationType: 'toast',
        msg: 'Admin changed your permissions',
    }
}