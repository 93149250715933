import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import useAuth from '../../auth/hooks/useAuth'
import { Button } from 'react-bootstrap'
import { reloadPage } from '../../../utils/helpers'

const Error500: FC = () => {
  const { logout } = useAuth()
  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2qx text-gray-900 mb-4'>System Error</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>
        Oops something went wrong. If problem not resolved then please <span className='fw-bolder text-black'>Refresh</span> the page
      </div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className='mb-11'>
        <img
          src={toAbsoluteUrl('media/auth/access_denied.jpg')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('media/auth/access_denied.jpg')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0'>

      </div>
      <div className="d-flex flex-wrap flex-row flex-center">
        <Button className='btn btn-lg btn-primary me-2' onClick={reloadPage}>REFRESH PAGE</Button>
      </div>
      {/* end::Link */}
    </>
  )
}

export default Error500
