import { useEffect } from "react";

interface IBrowserTabEvent {
    eventName: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (data?: any) => void;
}
interface IEvents extends Record<string, IBrowserTabEvent> { }
export const BROADCAST_EVENT = {
    LOGOUT_EVENT: 'logout',
    LOGIN_EVENT: 'login',
};

let broadcastChannelInstance: BroadcastChannel | null = null;

const getBroadcastChannel = () => {
    if (!broadcastChannelInstance) {
        broadcastChannelInstance = new BroadcastChannel('broadcast-event');
    }
    return broadcastChannelInstance;
};





const useBrowserTabEvent = (events: IEvents) => {
    useEffect(() => {
        // Check if BroadcastChannel is supported
        if (typeof BroadcastChannel !== "undefined") {
            const broadcastChannel = getBroadcastChannel();
            // Listen for messages from other tabs
            const broadcastChannelEventHandler = (event: MessageEvent) => {
                let eventData = {};
                const eventName = event.data.eventName
                if (event.data && Object.values(BROADCAST_EVENT).includes(eventName)) {
                    eventData = event.data
                }
                const e = events[eventName];
                if (e) e.callback(eventData);

            };

            // window.addEventListener('message', broadcastChannelEventHandler);
            broadcastChannel.onmessage = (event) => {
                broadcastChannelEventHandler(event);
            };
            return () => {
                // Close only when unmounting
                // broadcastChannel.close();
                // window.removeEventListener('message', broadcastChannelEventHandler);
            };
        } else {
            // Fallback to localStorage
            const localStorageEventHandler = (event: StorageEvent,) => {
                let eventData = {};
                const eventName = event.key as string;
                if (event.key && Object.values(BROADCAST_EVENT).includes(event.key)) {
                    eventData = event.newValue ? JSON.parse(event.newValue as string) : {};
                    const e = events[eventName];
                    if (e) {
                        e.callback(eventData);
                    }
                }
            };
            window.addEventListener('storage', localStorageEventHandler);
            return () => {
                window.removeEventListener('storage', localStorageEventHandler);
            };
        }
    }, [events]);
};



// Use this function in your hook and sendTabEvent
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendTabEvent = (eventName: string, payload?: any) => {
    const eventData = {
        eventName,
        timestamp: Date.now(),
        ...payload,
    };
    const channel = getBroadcastChannel();
    if (channel) {
        try {
            channel.postMessage(eventData); // Send structured data
        } catch (error) {
            console.error("Failed to post message:", error);
        }
    } else {
        // Fallback to localStorage
        try {
            localStorage.setItem(eventName, JSON.stringify(eventData)); // Store structured data
            // const event = new Event('storage');
            // window.dispatchEvent(event);
        } catch (error) {
            console.error("Failed to set item in localStorage:", error);
        }
    }
};

export default useBrowserTabEvent;